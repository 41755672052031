/*
$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
}
*/

/*
body {
  color: #777;
  background-color: #f3f7f5;
}
*/


table {
  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}

blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  quotes: none;
  padding: .25em .25em .25em 3em;
}

h1, h2, h3, h4, h5, h6 {
  color: #444;
}

.intro-block {
  background-color: white;
  //padding: 3em 2em;
  //margin-top: 2em;
  //margin-bottom: 2em;
  //margin: 15px 0;
  text-align: center;
}

.intro-block:hover {
  //box-shadow: 0 0 10px 5px grey;
  box-shadow: 0 0 20px rgba(0,0,0,.15);
}

// Set default height for the hero overlay
.hero-overlay-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  margin-bottom: 0;
  background-position: center;
}

.hero-overlay-inner {
  background: rgba(0, 0, 0, .5);
}

@include media-breakpoint-up(lg) {
  .hero-overlay-container {
    height: 350px;
  }
}

.home-carousel-caption {
  position: absolute;
  right: 15%;
  top: 10%;
  left: 15%;
  bottom: 10%;
  z-index: 10;
  padding: 0em;
  min-width: 33%;
  //max-width: 60%;
  color: #fff;
  text-align: left;
  background: rgba(0, 0, 0, .5);
  overflow: hidden;
}

@include media-breakpoint-up(md) {
  .home-carousel-caption {
    padding: 1em;
  }
}

@include media-breakpoint-up(xl) {
  .home-carousel-caption {
    padding: 2em;
  }
}

// Blog overlay transistions
.blog-entry {
  position: relative;
  //width: 50%;
}

.blog-image {
  width: 100%;
  height: auto;
}

.blog-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}

.blog-text {
  color: white;
  //font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.blog-entry:hover .blog-overlay {
  width: 40%;
}


$body-bg: #eeeff0; //#f3f7f5;
//$body-bg: #e00c0c;
$body-color: #777;

$font-family-base: "Source Sans Pro", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$enable-responsive-font-sizes: true !default;

$theme-colors: (
  "primary": #17938b,
  "secondary": #1e2c35, // #44505b,
  "danger": #ff4136
);

@import 'bootstrap/bootstrap';

@import "main";